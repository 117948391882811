<!--
 * @Descripttion: 云店开通审核
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-09-24 13:51:11
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-09-24 14:31:18
-->
<template>
    <div class="cloudStoreOpenAudit">
        <div class="oa-title flex-sbc">
            <div class="mt-title">云店开通审核</div>
            <div class="mt-right flex-fec">
                <div class="r-tabs flex-wc">
                    <div class="tab flex-cc" :class="tabIndex == item.id?'active':''"
                        v-for="(item,index) in tabList" :key="index"
                        @click="clickTab(item)">
                        {{item.name}}
                    </div>
                </div>
                <div class="flex-wc" >
                    <el-input v-model="keyworld" placeholder="请输入搜索内容" style="width: 300px;margin-right: 20px"></el-input>
                    <el-button @click="clickSearch" type="primary" >搜索</el-button>
                </div>
            </div>
        </div>
        <div class="oa-content">
            <el-table
                :data="tableData"
                style="width: 100%;"
                border
                >
                <el-table-column align="center" label="用户名称">
                    <template slot-scope="scope">
                        <div>{{scope.row.user.name}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="用户手机号">
                    <template slot-scope="scope">
                        <div>{{scope.row.user.phone}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="store_name" label="云店名称"></el-table-column>
                <el-table-column align="center" label="云店LOGO">
                    <template slot-scope="scope">
                        <el-image 
                            style="height: 50px"
                            :src="scope.row.logo" 
                            :preview-src-list="scope.row.logoList">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="云店行业">
                    <template slot-scope="scope">
                        <div>{{scope.row.industry}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="营业执照图片">
                    <template slot-scope="scope">
                        <el-image 
                            style="height: 50px"
                            :src="scope.row.business_license" 
                            :preview-src-list="scope.row.businessList">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="real_name" label="姓名"></el-table-column>
                <el-table-column align="center" prop="id_card" label="证件号码"></el-table-column>
                <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
                <el-table-column align="center" prop="address" label="门店地址"></el-table-column>
                <el-table-column align="center" label="审核操作" width="180">
                    <template slot-scope="scope">
                        <div class="btn-wrap flex-cc" v-if="scope.row.status == 0">
                            <div class="btn flex-cc" @click="clickCheek(scope.row,1)">通过</div>
                            <div class="btn no flex-cc" @click="clickCheek(scope.row,2)">不通过</div>
                        </div>
                        <div class="btn-wrap flex-cc" v-else>
                            <div v-if="scope.row.status == 1" class="btn flex-cc">已通过</div>
                            <div v-if="scope.row.status == 2" class="btn no flex-cc">未通过</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-wrap">
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="page_num"
                    :page-sizes="[20, 50, 100, 200]"
                    :page-size="page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 提示 -->
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">{{delName}}</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'cloudStoreOpenAudit',
            tabList: [{
                name: '待审核',
                id: 0
            },{
                name: '已审核',
                id: 1
            },{
                name: '已拒绝',
                id: 2
            }],
            tabIndex: 0,
            page_num: 1,
            page_size: 20,
            keyworld: '',
            tableData: [],
            total: 0,
            isDel: false,
            delName: '',
            examine_status: '',
            cheekId: '',
        }
    },
    mounted () {
        this.getCloudOpenList()
    },
    methods: {
        clickConfirm(){
            this.isDel = false
            let params = {
                id: this.cheekId,
                examine_status: this.examine_status,
            }
            api.post('/service/system/order_goods_cloud_open_apply_examine', params, (res) =>  {
                if(this.examine_status == 1){
                    this.$message.success('已通过')
                }else{
                    this.$message.success('已拒绝')
                }
                this.getCloudOpenList()
            });
        },
        // 审核
        clickCheek(row,index){
            this.examine_status = index
            this.cheekId = row.id
            this.isDel = true
            if(index == 1){
                this.delName = '确定通过该门店申请吗？'
            }else{
                this.delName = '确定拒绝该门店申请吗？'
            }
        },
        handleCurrentChange(val) {
            this.page_num = val;
            this.getCloudOpenList();
        },
        handleSizeChange(val){
            this.page_size = val;
            this.getCloudOpenList();
        },
         clickSearch(){
            this.page_num = 1;
            this.getCloudOpenList()
        },
        clickTab(item){
            this.tabIndex = item.id
            this.page_num = 1;
            this.getCloudOpenList()
        },
        // 获取审核列表
        getCloudOpenList(){
            let params = {
                page_num: this.page_num,
                page_size: this.page_size,
                info: this.keyworld,
                status: this.tabIndex,
            }
            api.post('/service/system/get_order_goods_cloud_open_apply_list', params, (res) =>  {
                res.data.list.forEach(v => {
                    v.logoList = []
                    v.logoList.push(v.logo)
                    v.businessList = []
                    v.businessList.push(v.business_license)
                });
                this.tableData = res.data.list
                this.total = res.data.count
            });
        },
    },
}
</script>

<style lang='scss'>
.cloudStoreOpenAudit{
    .oa-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-right{
            .r-tabs{
                margin-right: 40px;
                .tab{
                    width: 80px;
                    height: 35px;
                    border-radius: 6px;
                    border: 1px solid #ebebeb;
                    margin-right: 10px;
                    font-size: 15px;
                    color: #000;
                    cursor: pointer;
                }
                .active{
                    background: #0068ff;
                    border-color: #0068ff;
                    color: #fff;
                }
            }
        }
    }
    .oa-content{
        padding: 20px;
        background: #fff;
        margin: 20px;
        border-radius: 12px;
        height: calc(100vh - 250px);
        .btn-wrap{
            .btn{
                width: 70px;
                height: 30px;
                border-radius: 6px;
                background: #B2E7DC;
                color: #00b08b;
                font-size: 14px;
                margin: 0 10px;
                cursor: pointer;
            }
            .no{
                background: #FDE5E6;
                color: #e60012;
            }
        }
    }
    .page-wrap{
        width: calc(100vw - 290px);
        z-index: 999;
        background: #fff;
        height: 60px;
        position: fixed;
        bottom: 0px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 5px 2px rgba(150, 150, 150, 0.1);
        .pagination-wrap{
            margin-left: 250px;
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
.el-dialog{
    margin: 0;
    margin-top: 0vh !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 10px;
}
</style>
